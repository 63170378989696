<template>
  <div class="flex flex-col">
    <!-- <span class="text-title-small text-white opacity-[0.87]">
      {{ $t("playMedia", { media: store.media.name }) }}
    </span> -->
    <div class="w-full flex flex-col mt-8 md:mt-16 gap-y-6">
      <div class="flex justify-between items-center gap-x-3">
        <FormSelect
          :items="seasonsList"
          element-id="seasons"
          class="flex-shrink-0 min-w-[12rem]"
          v-model="store.selectedSeason"
        ></FormSelect>
        <div class="flex-shrink flex justify-end items-center gap-x-3">
          <button
            class="w-12 h-12 rounded-[4px] bg-white/10 hover:bg-white/[.15] active:bg-white/30"
            @click="viewToggle()"
          >
            <Icon v-if="viewValue" name="IconList" size="24"></Icon>
            <Icon v-else name="IconGrid" size="24"></Icon>
          </button>
          <button
            class="block md:hidden w-12 h-12 rounded-[4px] bg-white/10 hover:bg-white/[.15] active:bg-white/30"
            @click="sortToggle()"
          >
            <Icon name="IconSort" size="24" class="rtl:-scale-x-100"></Icon>
          </button>
          <FormSelect
            :items="episodeSorting"
            class="hidden md:flex"
            element-id="sorting"
            v-model="selectedSorting"
          ></FormSelect>
        </div>
      </div>

      <div v-if="episodes" class="flex flex-col gap-y-4">
        <DetailSeriesGrid
          v-if="viewValue"
          :episodes="episodes"
          :watch-history="history"
          @on-episode-click="onEpisodeClick"
        ></DetailSeriesGrid>
        <DetailSeriesList
          v-else
          :episodes="episodes"
          :watch-history="history"
          @on-episode-click="onEpisodeClick"
        ></DetailSeriesList>
        <div v-if="hasMoreEpisodes" class="flex justify-center py-14">
          <ButtonsCta
            :is-loading="isLoading"
            class="variant-secondary outlined"
            @click="onLoadMore"
          >
            {{ $t("Load more") }}
          </ButtonsCta>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToggle } from "@vueuse/core";
import { useDetailStore } from "@/stores/detail";
import { useRadioStore } from "@/stores/radio";
import { useSessionStore } from "@/stores/session";
import { SEASON_SIZE } from "@/constants";

import { PLAYERS } from "@/constants";

const { t } = useI18n();
const { fetchEpisodes } = useFetchAsset();
const { fetchEpisodeHistory } = usePcw();

const route = useRoute();
const router = useRouter();
const store = useDetailStore();
const sessionStore = useSessionStore();
const selectedSorting = ref();
const episodes = ref();
const history = ref();
const isLoading = ref(false);

const firstLoad = ref(true);

const episodeSorting = [
  {
    key: 1,
    label: "Oldest to newest",
    value: "asc",
  },
  {
    key: 2,
    label: "Newest to oldest",
    value: "desc",
  },
];

// toggle the grid/list view
const [viewValue, viewToggle] = useToggle();
const [sortValue, sortToggle] = useToggle();
const { whichTypeNeedDispatch } = useADtvGTM();

const seasonsList = computed(() => {
  return store?.media?.tvShowSeasons?.map((s) => {
    const seasonLabel = s.name || t("seasonLabel", { s: s.seasonNumber });

    let label = seasonLabel;
    // if numberOfEpisodes is non-null and gt 0, show it
    const count = s.numberOfEpisodes;
    if (count > 0) {
      const episodeCount = t("episodeCount", { count });
      label = `${seasonLabel} (${episodeCount})`;
    }

    return {
      ...s,
      label,
      key: `${s?.seasonNumber} + ${s?.externalId}`,
    };
  });
});

const hasMoreEpisodes = computed(() => {
  return episodes?.value?.size > episodes?.value?.contents?.length;
});

/**
 * onMounted, set the selected season.
 * if there's an externalSeasonId from the query, use that to find the season
 * otherwise (ie the contentType is Series), select the first season
 */
onMounted(async () => {
  // if ended is true: show first episode first
  const sortIndex = store.selectedSeason?.isEnded ? 0 : 1;
  selectedSorting.value = episodeSorting[sortIndex];
  // look at useMediaAssets.fetchSeasonAssets for explanation
  const { externalSeasonId } = route.query;
  store.selectedSeason =
    seasonsList?.value?.find(
      (season) => season.externalId == externalSeasonId
    ) || seasonsList?.value?.[0];
  viewValue.value = false;
  firstLoad.value = false;
});

const fetchNextToWatch = async () => {
  const isAGuest = !sessionStore.isAuthenticated;
  const noEpisodes = (episodes.value || []).length === 0;
  if (isAGuest || noEpisodes) return;

  history.value = await fetchEpisodeHistory(
    episodes.value?.contents?.map((e) => e.assetExternalId).join(",")
  );
};

watch(sortValue, () => {
  selectedSorting.value = episodeSorting[sortValue.value ? 0 : 1];
});

/**
 * watches for season and sort dropdowns
 * assesses which changed -- season or sort
 *
 * fetch api everytime season changes however, when sorting changes,
 * assess if all episodes have been fetched. if true then
 * just sort local episodes. otherwise fetch next batch of episodes
 */
watch(
  [() => store.selectedSeason, selectedSorting],
  async ([newSeason, newSort], [oldSeason, oldSort]) => {
    const seasonChanged = newSeason != oldSeason;
    const sortChanged = newSort != oldSort;

    // if both of these are true, it means it's the first load
    if (seasonChanged && sortChanged) {
      if (newSeason) {
        await fetchContent();
      }
    }

    if (seasonChanged && !sortChanged) {
      if (!newSeason) return;
      await fetchContent();
      router.replace({
        query: {
          ...route.query,
          video_external_id: episodes.value.contents?.[0]?.assetExternalId,
          externalContentId: store.selectedSeason.externalId,
          externalSeasonId: store.selectedSeason.externalId,
        },
      });
    }

    if (sortChanged && !seasonChanged) {
      if (hasMoreEpisodes.value) {
        await fetchContent();
      } else {
        sortLocalEpisodes();
      }
    }
  },
  { deep: true }
);

async function fetchContent() {
  store.episodes = episodes.value = await fetchEpisodes(
    store.selectedSeason.externalId,
    {
      from: 0,
      count: SEASON_SIZE,
      order: selectedSorting.value.value,
    }
  );

  store.nextToWatch = episodes.value.contents[0];

  await fetchNextToWatch();
}

const onLoadMore = async () => {
  isLoading.value = true;
  const moreEpisodes = await fetchEpisodes(store.selectedSeason.externalId, {
    from: episodes.value.contents.length,
    count: SEASON_SIZE,
    order: selectedSorting.value.value,
  });

  episodes.value.contents = [
    ...episodes.value.contents,
    ...moreEpisodes.contents,
  ];
  isLoading.value = false;
};

const onEpisodeClick = async (episode) => {
  if (useNeedLoginPopup().isForceRegister(episode)) {
    return;
  }
  if (useNeedLoginPopup().isPlusNeedPopup(episode)) {
    return;
  }
  if (store.isPodcast) {
    const radioStore = useRadioStore();
    const radioPlayer = useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);
    // disable sending PCW updates for podcasts
    // if (radioStore.widgetStation) {
    //   if (useSessionStore().isAuthenticated) {
    //     await usePcw().sendWatchHistory(
    //       radioStore.widgetStation.assetExternalId,
    //       radioPlayer.currentTime
    //     );
    //   }
    // }

    if (radioStore.widgetStation?.assetExternalId == episode.assetExternalId) {
      return radioPlayer.togglePlayback();
    }

    radioStore.$patch((state) => {
      state.episodes = episodes.value.contents;
      state.seriesDetail = store.media;
      state.widgetStation = {
        ...episode,
        isPodcast: true,
      };

      state.showWidget = true;
    });

    whichTypeNeedDispatch("radio", radioStore.widgetStation);
    return;
  }
};

/**
 * sorts existing episodes array.
 * only happens when all episodes have been fetched.
 */
const sortLocalEpisodes = () => {
  const eps = episodes?.value?.contents;
  const operator = selectedSorting.value?.key == 1 ? 1 : -1; // 1 for ascending, -1 for descending
  const sorted = eps?.sort((a, b) => {
    const episodeNumberA = a.tvShowReference.episodeNumber;
    const episodeNumberB = b.tvShowReference.episodeNumber;

    return (episodeNumberA - episodeNumberB) * operator;
  });
  if (sorted) {
    episodes.value.contents = sorted;
  }
};
</script>

<script>
export default { name: "SeriesView" };
</script>
