<template>
  <div
    :class="[
      { 'bg-brand-blue-900': showHighlight },
      store.isPodcast ? 'flex-row gap-x-2' : 'flex-col gap-x-4',
    ]"
    class="w-full flex md:flex-row items-start rounded-lg duration-100 transition-all"
  >
    <div
      :style="{
        backgroundImage: `url('${poster}')`,
      }"
      :class="[
        store.isPodcast
          ? 'thumbnail circle aspect-square'
          : 'thumbnail w-full md:w-4/12 aspect-video',
      ]"
      class="flex-shrink-0 relative rounded-lg flex justify-center items-center bg-center bg-no-repeat bg-cover"
    >
      <slot name="clicker"> </slot>

      <div
        :style="{ width: `${progress}%` }"
        class="absolute bottom-0 bg-brand-red h-[6px] left-0 rounded-bl-lg"
      ></div>
    </div>
    <div
      :class="{ 'p-2': !store.isPodcast }"
      class="flex flex-col justify-center"
    >
      <h2
        class="text-start text-title-medium line-clamp-1 md:text-title-big text-white opacity-[0.87]"
      >
        {{ episode.displayName || episode.name }}
      </h2>
      <small
        class="text-start text-label-medium md:text-title-small text-white opacity-50"
      >
        {{ episode.durationPretty }}
      </small>
      <p
        v-if="episode.description"
        class="text-start pt-2 pe-4 text-body-small md:text-body-big text-white opacity-50 line-clamp-3"
      >
        {{ episode.description }}
      </p>
    </div>
  </div>
  <!-- fix below -->
  <div v-if="showBar" class="w-full border-b border-white/10"></div>
</template>

<script setup>
import { useDetailStore } from "@/stores/detail";
import { useRadioStore } from "@/stores/radio";
import { PLAYERS } from "@/constants";

const store = useDetailStore();

const props = defineProps({
  episode: {
    type: Object,
    required: true,
  },
  showBar: {
    type: Boolean,
    default: () => false,
  },
});

const poster = computed(() => {
  const mediaAssets = useMediaAssets(store.selectedSeason);
  return (
    props.episode?.poster || mediaAssets?.horizontalimage || mediaAssets?.logo
  );
});

const radioPlayer = computed(() => {
  return useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);
});

const radioStore = useRadioStore();

const showHighlight = computed(() => {
  if (radioPlayer) {
    return (
      radioStore.widgetStation?.assetExternalId == props.episode.assetExternalId
    );
  }
  return props.episode.history?.viewPercentage == 100;
});

const progress = computed(() => {
  if (
    radioPlayer.value &&
    radioStore.widgetStation?.assetExternalId == props.episode.assetExternalId
  ) {
    return radioPlayer.value.indicatorPosition * 100;
  }
  return props.episode.history?.viewPercentage;
});
</script>

<style lang="scss" scoped></style>
