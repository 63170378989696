<!-- 
  TODO: Rework existing component and delete this for trailer button
  This component is copy of "components/page-builder/HeroBanner/Item/index.vue"

 -->

<template>
  <div class="hero-item relative w-full h-full border-inherit">
    <div
      v-if="bannerToShow"
      :style="{
        backgroundImage: `url(${bannerToShow})`,
      }"
      :class="[imageClass]"
      class="w-full h-full bg-center bg-no-repeat bg-cover"
    ></div>
    <div
      v-else
      :class="[imageClass]"
      class="w-full h-full aspect-video bg-center bg-no-repeat bg-cover flex items-center justify-center"
    >
      <img :src="useFallbackImage().url.value" alt="" />
    </div>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";

import { useDetailStore } from "@/stores/detail";
import { useTrailerStore } from "@/stores/trailer";

const props = defineProps({
  item: Object,
  index: Number,
  isMuted: {
    type: Boolean,
    default: () => false,
  },
  manualTrailer: {
    type: Boolean,
    default: () => false,
  },
  isActive: {
    type: Boolean,
    default: () => false,
  },
  allowTrailer: {
    type: Boolean,
    default: () => true,
  },
  imageClass: String,
  playerId: String,
  inDetail: Boolean,
});

const sessionStore = useSessionStore();

const emit = defineEmits("on-next");

const detailStore = useDetailStore();
const route = useRoute();
const playerStore = ref();
const timer = ref();

const trailerStore = useTrailerStore();

const parsedItem = computed(() => {
  if (props.inDetail) {
    return {
      ...props.item.media,
      images: props.item.media.images || useMediaAssets(props.item.media),
      trailer: props.item.trailer,
    };
  }

  return {
    ...props.item,
    images: useMediaAssets(props.item),
  };
});

/**
 * for live program:
 * in herobanner: if no banner in place, use channel banner
 */
const bannerToShow = computed(() => {
  try {
    const itemObj = props.inDetail ? props.item.media : props.item;

    if (!itemObj.images) {
      itemObj.images = useMediaAssets(itemObj);
    }

    const imageAssetsEmpty =
      Object.keys(itemObj.images)?.length === 0 || !itemObj.images?.banner;
    const hasChannel = Object.keys(itemObj).includes("channel");

    if (itemObj.type == "LiveProgram" && imageAssetsEmpty && hasChannel) {
      const images = useMediaAssets(itemObj.channel);
      return images.banner;
    }
    return parsedItem.value.images?.banner;
  } catch (error) {
    return parsedItem.value.images?.banner;
  }
});

onMounted(async () => {
  await nextTick();
  trailerStore.transitionState = "banner";
  const hasMediaQuery = Object.keys(route.query).includes("media");
  const hasDetailStore = detailStore.trailer;

  if (hasDetailStore && props.inDetail) {
    const hasTrailer = Object.keys(detailStore?.trailer || {})?.length > 0;
    if (!hasTrailer) return;
    initializeTimer();
  }

  if (hasMediaQuery === false && !props.inDetail) {
    initializeTimer();
  }
});

onBeforeUnmount(() => {
  timer.value && clearTimeout(timer.value);
  trailerStore.transitionState = "banner";
});

const bannerTrailer = computed(() => {
  return parsedItem.value.trailer || parsedItem.value.attachmentPlayingInfo;
});

/**
 * initializeTimer
 * start the transition from banner to trailer
 *
 * goes through validations first
 * 1) is it has a trailer
 * 2) if detail modal is open (hasDetailStore) and this component is in detail
 * 3) if detail modal is not open and this component is not in detail
 */
const initializeTimer = () => {
  if (props.manualTrailer) {
    return;
  }
  if (sessionStore.activeProfile?.property?.autoplay_previews === false) {
    return;
  }

  if (!bannerTrailer.value) {
    return;
  }

  timer.value = setTimeout(() => {
    timer.value && clearTimeout(timer.value);
    trailerStore.transitionState = "trailer";
  }, 4500);
};

watch(
  () => props.isMuted,
  () => {
    if (playerStore.value?.player) {
      playerStore.value.player.muted = props.isMuted;
      playerStore.value.player.video_.muted = props.isMuted;
    }
  }
);

watch(
  () => props.allowTrailer,
  (newVal) => {
    if (newVal === false) {
      trailerStore.transitionState = "banner";
      playerStore.value?.player.destroy();
      emit("on-trailer-start", false);
    }
  }
);

// when trailer ends, emit onNext to show the next item in the carousel
watch(
  () => playerStore.value?.playbackState,
  async (newVal) => {
    if (newVal == "ended") {
      await nextTick();
      trailerStore.transitionState = "banner";
      await playerStore.value?.player?.destroy();
      playerStore.value?.$reset();
      emit("on-next");
    }
  }
);

watch(
  () => props.isActive,
  async (newVal) => {
    if (newVal) {
      trailerStore.transitionState = "banner";
      initializeTimer();
    } else {
      clearTimeout(timer.value);
      trailerStore.transitionState = "banner";
      await playerStore.value?.player?.destroy();
      await playerStore.value?.$reset();
    }
  },
  { deep: true }
);

watch(
  () => route.query,
  (newQuery) => {
    if (Object.keys(newQuery).includes("media")) {
      trailerStore.transitionState = "banner";
      /**
       * trigger on next if it's on detail page.
       * this triggers the onTrailerEnd and hides the trailer controls.
       */
      const isShowOrSeason = ["Series", "Season"].includes(route.query.type);
      if (props.inDetail && isShowOrSeason) {
        nextTick().then(() => {
          emit("on-next");
          clearTimeout(timer.value);
          initializeTimer();
        });
        return;
      }

      clearTimeout(timer.value);
      return;
    }

    trailerStore.transitionState = "banner";

    initializeTimer();
  }
);
</script>

<script>
export default { name: "BannerItem" };
</script>

<style lang="scss" scoped>
.bannerfade-enter-active,
.bannerfade-leave-active {
  transition: opacity 0.5s ease 500ms;
}

.bannerfade-leave-to,
.bannerfade-enter-from {
  opacity: 0;
}
</style>
