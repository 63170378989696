<template>
  <div class="">
    <div class="hero__container aspect-video relative rounded-t-2xl">
      <div v-if="!store.isPodcast" class="container__gradients rounded-t-2xl">
        <div
          class="z-10 rounded-t-2xl absolute bottom-0 left-0 w-2/3 h-full gradient horizontal"
        />
        <div
          class="z-10 absolute -bottom-1 left-0 w-full h-1/2 gradient vertical"
        />
      </div>
      <template v-if="bannerData.media">
        <div
          v-if="isXs && store.isPodcast"
          class="h-full px-7 flex flex-col justify-center items-center gap-y-10 pt-8"
        >
          <div class="w-full flex justify-between">
            <div class="flex flex-col gap-y-5">
              <div
                id="logo"
                class="text-headline-small md:text-display-medium text-white opacity-[0.87] line-clamp-2"
              >
                {{ podcastName }}
              </div>
            </div>
            <CarouselTypeBigSquare
              :item="bannerData.media"
              :show-title="false"
            ></CarouselTypeBigSquare>
          </div>
          <div class="flex flex-col gap-y-2 w-full">
            <OverlayCtaSection
              :key="bannerData.media?.externalId"
              :media="bannerData.media"
              :class="[
                { 'opacity-0': isTrailerStarted },
                { 'opacity-100': isUiShowing },
              ]"
              :in-detail="inDetail"
              class="md:mt-6 touch-auto pointer-events-auto"
            >
              <template #primary>
                <ButtonsCta
                  v-if="store.nextToWatch || store.media"
                  class="variant-primary py-2"
                  @click="onPlay('next')"
                >
                  <div class="w-full flex items-center gap-x-[6px]">
                    <Icon name="IconPlay" size="24"></Icon>
                    <span
                      class="font-Abu md:text-button-medium text-button-big text-white"
                    >
                      {{ $t("Play") }}
                    </span>
                  </div>
                </ButtonsCta>
              </template>
            </OverlayCtaSection>
            <div
              v-if="store.nextToWatch"
              class="text-title-small text-white opacity-[0.87]"
            >
              {{ $t("playMedia", { media: store.nextToWatch?.name }) }}
            </div>
          </div>
        </div>

        <!-- default view -->
        <PageBuilderHeroBannerOverlay
          v-else
          :item="bannerData.media"
          :active-index="0"
          :is-trailer-started="trailerStore.trailerStarted"
          in-detail
          class="z-10 w-full h-full aspect-[16/7] absolute end-0 start-0 px-6 md:px-11 lg:px-[45px] grid-container detail-banner gap-2 content-end"
        >
          <template #logo>
            <div
              v-if="store.isPodcast"
              id="logo"
              class="text-display-medium text-white opacity-[0.87] line-clamp-2"
            >
              {{ podcastName }}
            </div>
          </template>
          <template #tags>
            <OverlayFieldTags :media="store.media" in-detail></OverlayFieldTags>
            <OverlayDescriptionTags
              :media="store.media"
              in-detail
              opacity-class="opacity-80"
            ></OverlayDescriptionTags>
          </template>
          <template #controller>
            <PageBuilderHeroBannerTrailerControls
              v-if="trailerStore.trailerStarted"
              v-model:isMuted="isMuted"
              @on-stop-trailer="allowTrailer = false"
            ></PageBuilderHeroBannerTrailerControls>
          </template>
          <template #primary>
            <template
              v-if="mediaDeterminator.isAShow && !mediaDeterminator.isPodcast"
            >
              <NuxtLink
                @click.stop="navigateNow"
                class="px-4 py-2 h-unset md:h-9 lg:h-12 md:py-2 md:px-6 variant-primary text-body-medium md:text-body-big rounded-full outline-none backdrop-blur-sm duration-100 transition-all disabled:cursor-not-allowed whitespace-nowrap"
              >
                <div
                  class="h-full w-full flex justify-center items-center font-bold transition-all duration-200"
                >
                  <Icon name="IconPlay" size="24"></Icon>
                  <span
                    class="font-Abu md:text-button-medium text-button-big text-white"
                  >
                    {{ $t("Play") }}
                  </span>
                </div>
              </NuxtLink>

              <ButtonsCta
                v-if="hasTrailer"
                class="variant-secondary !py-2 !px-2 sm:!px-4"
                @click.stop="
                  () => {
                    console.log('start trailer');
                    watchStore.media = bannerData.media;
                    trailerStore.trailerPlayinfo = bannerData.trailer;
                  }
                "
                :disabled="trailerStore.isTrailerPlaying"
              >
                <div
                  class="w-full flex items-center gap-x-[6px]"
                  :class="[
                    trailerStore.isTrailerPlaying
                      ? 'opacity-50'
                      : 'opacity-100',
                  ]"
                >
                  <Icon name="IconTrailer" size="20"></Icon>
                  <span
                    class="font-Abu text-button-big text-white font-normal hidden sm:inline-flex"
                  >
                    {{ $t("trailer") }}
                  </span>
                </div>
              </ButtonsCta>
            </template>
            <template v-else>
              <ButtonsCta
                v-if="store.nextToWatch || store.media"
                class="variant-primary py-2"
                @click="onPlay('last')"
              >
                <div class="w-full flex items-center gap-x-[6px]">
                  <Icon name="IconPlay" size="24"></Icon>
                  <span
                    class="font-Abu md:text-button-medium text-button-big text-white"
                  >
                    {{ $t("Play") }}
                  </span>
                </div>
              </ButtonsCta>

              <ButtonsCta
                v-if="hasTrailer"
                class="variant-secondary !py-2 !px-2 sm:!px-4"
                @click.stop="
                  () => {
                    watchStore.media = bannerData.media;
                    trailerStore.trailerPlayinfo = bannerData.trailer;
                  }
                "
                :disabled="trailerStore.isTrailerPlaying"
              >
                <div
                  class="w-full flex items-center gap-x-[6px]"
                  :class="[
                    trailerStore.isTrailerPlaying
                      ? 'opacity-50'
                      : 'opacity-100',
                  ]"
                >
                  <Icon name="IconTrailer" size="20"></Icon>
                  <span
                    class="font-Abu text-button-big text-white font-normal hidden sm:inline-flex"
                  >
                    {{ $t("trailer") }}
                  </span>
                </div>
              </ButtonsCta>
            </template>
          </template>
          <template #cta-suffix>
            <div
              v-if="store.nextToWatch"
              class="text-title-small text-white opacity-[0.87]"
            >
              {{ $t("playMedia", { media: store.nextToWatch?.displayName }) }}
            </div>
          </template>
          <template #extraIcons>
            <TooltipWrapper trigger-id="thumbs" size="small">
              <ButtonsIcon
                :icon-name="thumbIcon"
                :breakpoints="{ lg: 24, md: 20, sm: 20 }"
                class="variant-secondary outlined"
              >
              </ButtonsIcon>
              <template #content>
                <div class="w-full h-full flex items-center justify-between">
                  <button @click="onThumbClick(true)">
                    <Icon name="IconThumbsUp" size="24"></Icon>
                  </button>
                  <button @click="onThumbClick(false)">
                    <Icon name="IconThumbsDown" size="24"></Icon>
                  </button>
                </div>
              </template>
            </TooltipWrapper>
          </template>
        </PageBuilderHeroBannerOverlay>
        <div
          v-if="!isXs && store.isPodcast"
          class="hero-item__podcast relative w-full h-full flex justify-end items-center pe-11 aspect-video rounded-t-2xl border-inherit"
        >
          <CarouselTypeBigSquare
            :item="bannerData.media"
            :show-title="false"
          ></CarouselTypeBigSquare>
        </div>
        <PageBuilderHeroBannerItemForDetail
          v-if="!store.isPodcast"
          :key="`banner-item-${bannerData.media.id}-in-detail`"
          :is-active="true"
          :index="0"
          :item="bannerData"
          :isMuted="isMuted"
          :allow-trailer="allowTrailer"
          :manual-trailer="true"
          :player-id="PLAYERS.DETAIL_PLAYER"
          :in-detail="true"
          class="w-full h-full aspect-video rounded-t-2xl"
          image-class="rounded-t-2xl"
          @on-trailer-start="onTrailerStart"
          @on-next="onTrailerEnd"
        ></PageBuilderHeroBannerItemForDetail>
      </template>
    </div>
    <div
      class="detail__info"
      :class="{ 'pb-12': ['Series', 'Season'].includes(route.query.type) }"
    >
      <DetailSeries
        v-if="['Series', 'Season'].includes(route.query.type)"
        class="flex flex-col gap-y-14 px-6 md:px-11 lg:px-[45px]"
      ></DetailSeries>
      <section
        v-if="castList"
        class="info__castlist pt-12 px-6 md:px-11 lg:px-[45px] flex flex-col gap-y-14"
      >
        <div
          id="castBlock"
          class="text-white opacity-[0.87] flex flex-col gap-y-1"
        >
          <h2
            class="text-title-medium md:text-title-big lg:text-headline-small"
          >
            {{ $t("Cast") }}
          </h2>
          <p class="text-body-medium md:text-body-big">{{ castList }}</p>
        </div>
      </section>
    </div>
    <div class="detail__similar">
      <DetailSimilar :key="route.query.media" class="pt-4"></DetailSimilar>
    </div>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { useDetailStore } from "@/stores/detail";
import { useSessionStore } from "@/stores/session";
import { useTrailerStore } from "@/stores/trailer";
import { SEO_PAGE_CATEGORY_ENUM } from "@/constants";
import { useWatchStore } from "@/stores/watch";

const { isUnAuthPopup, isPlusNeedPopup } = useNeedLoginPopup();
const { setContentRatings } = useProfilesApi();
const { fetchDetail, fetchSeasonTrailer } = useFetchAsset();

const { isXs } = useBreakpoints();

const localePath = useLocalePath();
const route = useRoute();
const store = useDetailStore();
const sessionStore = useSessionStore();
const trailerStore = useTrailerStore();
const watchStore = useWatchStore();

const isMuted = ref(false);
const playerStore = ref();
const thumbStatus = ref(null);
// const trailerStarted = ref(false);
const allowTrailer = ref(true);

const mediaDeterminator = computed(() => useMediaType(route.query));

await loadStates();

async function loadStates() {
  const {
    externalContentId,
    externalSeriesId,
    assetExternalId,
    type,
    contentType,
  } = route.query;

  const query = {
    externalContentId: externalContentId,
    externalSeriesId: externalSeriesId,
    assetExternalId: assetExternalId || externalContentId,
    type,
    contentType,
  };

  const media = await fetchDetail(query);

  store.media = {
    ...media,
    trailer: media.attachmentPlayingInfo,
  };
  store.trailer = store.media.attachmentPlayingInfo;

  if (mediaDeterminator.value.isSeason || mediaDeterminator.value.isSeries) {
    store.trailer = await fetchSeasonTrailer({
      ...route.query,
      externalSeasonId:
        route.query.externalSeasonId ??
        store.media.tvShowSeasons?.[0]?.externalId,
    });
  }
}

const createCanonURL = () => {
  const { externalSeriesId, contentType } = route.query;

  // TODO Add fix for Podcast
  if (contentType === "Podcast") {
    useCanonicalTag();
    return;
  }

  // Add canonical URL for SEO
  const seoBaseUrl = useRuntimeConfig().public.seoBaseUrl;
  let URLParams = [];

  switch (store?.media?.type?.toLowerCase()) {
    // Build URL for Series
    case "series": {
      // Build URL for Series - type
      switch (store.media.serviceType) {
        case SEO_PAGE_CATEGORY_ENUM.SHOWS: {
          URLParams.push("shows");
          break;
        }
        case SEO_PAGE_CATEGORY_ENUM.KIDS_PROGRAMS: {
          URLParams.push("kids/programs");
          break;
        }
        case SEO_PAGE_CATEGORY_ENUM.SPORTS: {
          URLParams.push("sports");
          break;
        }
        default: {
          URLParams.push("shows");
        }
      }
      // Build remaining URL
      URLParams.push(externalSeriesId);

      // Add name
      if (store?.media?.tvShowSeries?.name) {
        URLParams.push(encodeURIComponent(store.media.tvShowSeries.name));
      } else {
        URLParams.push(externalSeriesId);
      }

      // Add season number
      URLParams.push(`season-${store?.selectedSeason?.seasonNumber}`);

      break;
    }
    // Build URL for movies
    case "movie": {
      URLParams.push("movies");

      URLParams.push(store?.media?.videoAsset?.externalId);
      URLParams.push(encodeURIComponent(store?.media?.aggregatedVideo?.name));

      break;
    }
    default: {
      return;
    }
  }

  const url = useLocalePath()(`/${URLParams.join("/")}`);
  const SEOUrl = {
    id: "seo-can",
    rel: "canonical",
    href: `${seoBaseUrl}${url}`,
  };

  useHead({
    link: [...([SEOUrl].filter((link) => link.rel === "canonical") ?? [])],
  });

  useCanonicalTag([SEOUrl]);
};

const episodePath = computed(() => {
  const path = useEpisodePath({
    ...store.nextToWatch,
    ...store.media,
  })?.asString;

  return localePath(path);
});

const podcastName = computed(() => {
  return (
    bannerData.value.media.seriesName ||
    bannerData.value.media.name ||
    bannerData.value.media.shortName ||
    bannerData.value.media.aggregatedVideo?.name
  );
});

const thumbIcon = computed(() => {
  if (thumbStatus.value === null) {
    return "IconThumbs";
  }

  return thumbStatus.value ? "IconThumbsUpFilled" : "IconThumbsDownFilled";
});
onBeforeUnmount(async () => {
  await trailerStore.$reset();
  useFmaSdk().terminate();
});
onBeforeMount(async () => {
  await useFmaSdk().logPageNav(usePageId().ADULT_DETAIL);
});
// dont initialize the trailer timer when there's no trailer ;p
onMounted(async () => {
  // await loadStates();
  createCanonURL();
  await useFmaSdk().loaded();
  if (store.disableTransitions || !store.trailer) return;
});

const navigateNow = () => {
  const path = route.path;

  if (isPlusNeedPopup(store.nextToWatch)) {
    return;
  } else {
    navigateTo(episodePath.value);
  }
};

const onTrailerStart = (val) => {
  trailerStore.trailerStarted = val;
};

const onTrailerEnd = () => {
  trailerStore.trailerStarted = false;
  // store.transitionState = "trailer";
};

const onPlay = (type) => {
  console.log("FROM ----- ", type);
  // only happens on series
  if (store.nextToWatch) {
    if (isPlusNeedPopup(store.nextToWatch)) {
      return;
    }

    if (store.isPodcast) {
      return useRadioWidgetClickEvent({
        ...store.nextToWatch,
        isPodcast: true,
      });
    }

    let path = "/watch";
    if (sessionStore.activeProfile?.isKid) {
      path = "/kids/watch";
    }

    return navigateTo({
      path: localePath(path),
      query: store.nextToWatch.query,
    });
  }

  // but if it's a movie/podcast, open widget
  if (store.media.type == "Movie" && store.media.contentType == "Podcast") {
    if (store.media.isPlus && !sessionStore.isAuthenticated) {
      if (isPlusNeedPopup(store.media)) {
        return;
      }
    } else {
      return useRadioWidgetClickEvent({
        ...store.media,
        assetExternalId: store.media.aggregatedVideo.assetExternalId,
      });
    }
  }
  if (store.media.isPlus && !sessionStore.isAuthenticated) {
    if (isPlusNeedPopup(store.media)) {
      return;
    }
  } else {
    usePlayClickEvent(store.media, true);
  }
};

const bannerData = computed(() => {
  let id;
  if (["Series", "Season"].includes(route.query.type)) {
    const bannerData = {
      media: {
        ...store.media,
        ...store.selectedSeason,
        images: useMediaAssets(store.selectedSeason),
        type: route.query.type,
        contentType: route.query.contentType,
      },
      trailer: store.trailer,
      id: store.selectedSeason?.externalId,
    };

    return bannerData;
  }

  return {
    id,
    media: store.media,
    trailer: store.trailer,
  };
});

const hasTrailer = computed(() => {
  return Object.keys(store?.trailer || {})?.length > 0;
});

const castList = computed(() => {
  if (!store.media) return;

  let list = [];
  switch (route.query.type) {
    case "Movie":
      list = store.media?.aggregatedVideo?.contributors;
      break;
    case "Series":
    case "Season":
      list = store.selectedSeason?.contributors;
      break;

    default:
      break;
  }

  return list
    ?.filter((c) => c.role == "Actor")
    ?.map((a) => `${a.firstName} ${a.lastName}`)
    ?.join(", ");
});

const onThumbClick = async (value) => {
  if (isUnAuthPopup()) return;

  if (thumbStatus.value === value) {
    thumbStatus.value = null;
    return;
  }

  thumbStatus.value = value;

  /**
   * 1. In the movie detail page (/api/biz/video/aggregate/detail?type=Movie&client=json&externalContentId=xxx&assetExternalId=xx):
   *   - Use the "assetExternalId" value  to set the "content_ids" parameter.
   * 2. In the series detail page (/api/biz/video/aggregate/detail?type=Series&contentType=Series&client=json&externalSeriesId=xx):
   *   - Use the "externalId" value from the "tvShowSeries" object to set the "content_ids" parameter.
   */
  const mediaType = useMediaType(store.media);

  let content_ids;
  if (mediaType.isSeries) {
    content_ids = store.media.tvShowSeries.externalId;
  }

  if (mediaType.isMovie) {
    content_ids = store.media.aggregatedVideo.assetExternalId;
  }

  await setContentRatings({
    profile_id: sessionStore.activeProfile.external_id,
    like: value ? 1 : 0,
    // content_ids: route.query.externalContentId,
    content_ids,
  });
};

// when trailer ends, emit onNext to show the next item in the carousel
// watch(
//   () => playerStore.value?.playbackState,
//   (newVal) => {
//     if (newVal == "ended") {
//       store.transitionState = "hero";
//     }
//   }
// );

/**
 * watch for route query changes
 * then fetch new asset
 */
watch(
  () => route.query,
  async () => {
    await loadStates();
    createCanonURL();
  }
);

/**
 * finds the instance of the radio player
 * might return undefined if there were no radio player
 * instantiated.
 */
const radioPlayer = computed(() => {
  return useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);
});

/**
 * watch for radio player's playback state
 * when user clicks play on a paused widget,
 * oldVal will be paused and newVal will be playing
 *
 * in that scenario, isMuted.value should be true
 * meaning the attention is now on the widget and
 * no longer in the trailers
 */
watch(
  () => radioPlayer.value?.playbackState,
  (newVal, oldVal) => {
    if (oldVal == "paused" && newVal == "playing") {
      isMuted.value = true;
    }
  }
);

/**
 * watch for hero banner's trailer
 * when user chooses to unmute,
 * the radio player should be paused
 */
watch(
  () => isMuted.value,
  (newVal) => {
    // handle widget playback here
    if (newVal === false) {
      radioPlayer.value?.pause();
    }
  }
);
</script>

<script>
export default { name: "DetailView" };
</script>

<style lang="scss" scoped>
.slow-fade-enter-active,
.slow-fade-leave-active {
  transition: opacity 500ms ease;
}

.slow-fade-leave-to,
.slow-fade-enter-from {
  opacity: 0;
}
</style>
