<template>
  <div class="w-full grid grid-cols-1 gap-y-6">
    <template v-for="(e, key) in parsedEpisodes">
      <button v-if="store.isPodcast" @click="$emit('onEpisodeClick', e)">
        <DetailSeriesListEpisodeRow
          :key="key"
          :episode="e"
          :show-bar="key !== parsedEpisodes.length - 1"
          @onEpisodeClick="$emit('onEpisodeClick', e)"
        ></DetailSeriesListEpisodeRow>
      </button>
      <NuxtLink v-else @click.stop="handleNavigate(e)">
        <DetailSeriesListEpisodeRow
          :key="key"
          :episode="e"
          :show-bar="key !== parsedEpisodes.length - 1"
          class="touch-none pointer-events-none"
        >
          <template #clicker>
            <ButtonsCta class="variant-glass w-12 h-12" custom-class>
              <Icon name="IconPlay" size="24"></Icon>
            </ButtonsCta>
          </template>
        </DetailSeriesListEpisodeRow>
      </NuxtLink>
    </template>
  </div>
</template>

<script setup>
import { useDetailStore } from "@/stores/detail";
import { PLAYERS } from "@/constants";
const { isPlusNeedPopup } = useNeedLoginPopup();

const props = defineProps({
  episodes: {
    type: Object,
    required: true,
  },
  watchHistory: {
    type: Array,
  },
});

const store = useDetailStore();
const dateTimeFuncs = useDateTime();
const localePath = useLocalePath();

const radioPlayer = useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);

const parsedEpisodes = computed(() => {
  return props.episodes.contents.map((e) => {
    const history = props.watchHistory?.find(
      (h) => h.content.id === e.assetExternalId
    ) || { viewPercentage: 0, viewDuration: 0 };

    return {
      ...e,
      poster: store.isPodcast ? e.images.squareimage : e.images.horizontalimage,
      history,
      durationPretty: dateTimeFuncs.asDurationPretty(e.duration / 1000),
      images: {
        ...e.images,
        logo: e.images.squareimage,
      },
    };
  });
});

const handleNavigate = (e) => {
  if (isPlusNeedPopup(e)) return;
  navigateTo(buildEpisodePath(e));
};

const isWatched = (episode) => {
  return episode.tvShowReference.episodeNumber == 1;
};

const buildEpisodePath = (e) => {
  return localePath(useEpisodePath({ ...e, ...store.media }).asString);
};
</script>
