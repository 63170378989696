import { useRadioStore } from "@/stores/radio";
const { whichTypeNeedDispatch } = useADtvGTM();
export default function (media) {
  const radioStore = useRadioStore();
  // const radioPlayer = useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);

  /**
   * means this is a single episode podcast.
   * open the widget and hide the next/prev buttons
   */
  // if (media.type == "Movie" && media.contentType == "Podcast") {
  //   return;
  // }

  radioStore.$patch((state) => {
    state.widgetStation = {
      ...media,
      isPodcast: true,
    };

    state.showWidget = true;
  });

  whichTypeNeedDispatch("radio", radioStore.widgetStation);
}
