<template>
  <div class="flex flex-col gap-y-4">
    <div id="seriesGrid" class="flex flex-wrap -mx-2">
      <div
        class="flex aspect-square relative w-2/6 md:w-3/12 lg:w-2/12 h-auto p-2"
        :key="key"
        v-for="(e, key) in parsedEpisodes"
      >
        <button
          v-if="store.isPodcast"
          :class="[
            showHighlight(e) ? 'bg-brand-blue-900 text-white' : 'text-white/50',
            e.history.viewPercentage > 0 ? 'bg-brand-popup-trailer' : '',
          ]"
          class="gridbox aspect-square relative border-white/10 border hover:border-2 hover:border-white cursor-pointer"
          @click.stop="$emit('onEpisodeClick', e)"
        >
          <div class="w-full h-full flex justify-center items-center">
            <span class="text-headline-big">
              {{ e.tvShowReference.episodeNumber }}
            </span>
          </div>
          <div
            :style="{ width: `${e.history.viewPercentage}%` }"
            class="absolute bottom-0 h-[6px] bg-brand-red left-0"
          ></div>
        </button>
        <NuxtLink
          v-else
          :class="[
            showHighlight(e) ? 'bg-brand-blue-900 text-white' : 'text-white/50',
            e.history.viewPercentage > 0 ? 'bg-brand-popup-trailer' : '',
          ]"
          @click="handleNavigate(e)"
          class="gridbox aspect-square relative border-white/10 border hover:border-2 hover:border-white cursor-pointer"
        >
          <div class="w-full h-full flex justify-center items-center">
            <span class="text-headline-big">
              {{ e.tvShowReference.episodeNumber }}
            </span>
          </div>
          <div
            :style="{ width: `${e.history.viewPercentage}%` }"
            class="absolute bottom-0 h-[6px] bg-brand-red left-0"
          ></div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDetailStore } from "@/stores/detail";
import { useRadioStore } from "@/stores/radio";
import { PLAYERS } from "@/constants";
const { isPlusNeedPopup } = useNeedLoginPopup();

const props = defineProps({
  episodes: {
    type: Object,
    required: true,
  },
  watchHistory: {
    type: Array,
  },
});

const store = useDetailStore();
const localePath = useLocalePath();

const parsedEpisodes = computed(() => {
  return props.episodes.contents.map((e) => {
    const history = props.watchHistory?.find(
      (h) => h.content.id === e.assetExternalId
    ) || { viewPercentage: 0, viewDuration: 0 };

    return {
      ...e,
      history,
    };
  });
});

const handleNavigate = (e) => {
  if (isPlusNeedPopup(e)) return;
  navigateTo(buildEpisodePath(e));
};

const isWatched = (episode) => {
  return episode.tvShowReference.episodeNumber == 1;
};

const radioPlayer = computed(() => {
  return useAllPlayers().getStore(PLAYERS.RADIO_PLAYER);
});
const radioStore = useRadioStore();

const showHighlight = (episode) => {
  if (radioPlayer) {
    return radioStore.widgetStation?.assetExternalId == episode.assetExternalId;
  }
  return props.episode.history?.viewPercentage == 100;
};

const buildEpisodePath = (e) => {
  return localePath(useEpisodePath({ ...e, ...store.media }).asString);
};
</script>

<style lang="scss" scoped></style>
